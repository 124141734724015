import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import Newsletter from '../../components/Newsletter'
import GitHub from '../../images/github.svg'
import ProjectLinks from '../../atoms/ProjectLinks'
import DemoTimeline from '../../../content/posts/announcing-react-timeline/DemoTimeline'

export default function ProjectReactTimeline({ data, location }) {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Projects | React Timeline"
        keywords={[`projects`, `python`, `javascript`, `react`, 'open source']}
      />
      <section>
        <article>
          <h1>React Timeline</h1>
          <h2>Goal</h2>
          <p>
            <a href="https://react-timeline.com">React Timeline</a> lets users
            easily add responsive and customizable timelines to any React
            project. Originally conceived as a way for developers and designers
            to showcase their work and achievements (e.g. articles published,
            videos of conference talks, etc.), the library is also useful for{' '}
            <em>any</em> type of timeline where you want to display
            chronological events.
          </p>
          <p>
            The API provides high level <code>Event</code> components that lets
            users quickly build timelines, as well as low level components used
            to compose higher level events.
          </p>
          <p>
            Timelines are fully themable — every visual element, from the event
            wrapper, date, timeline, marker, and event contents can be
            customized via a <code>theme</code> object. Each component also
            exposes semantic CSS class names, making styling via traditional
            stylesheets possible. The goal here was to give the user as much
            control over the visual style as possible.
          </p>
          <p>
            React Timeline supports various layouts. By default, events
            alternate around the timeline (assuming the viewport is wide
            enough), but you can also provide a <code>layout</code> prop that
            changes how events are arranged.
          </p>
          <h2>Technologies</h2>
          <p>
            React Timeline uses Styled Components to define its visual elements
            as well as provide the ability to apply custom themes.
          </p>
          <p>
            The documentation is built with Storybook, which is a fantastic tool
            that lets you embed actual components alongside written
            documentation.
          </p>
          <p>
            Flexbox and a few media queries are responsible for providing the
            responsive layouts.
          </p>
          <h2>Live Demo</h2>
          <DemoTimeline />
          <ProjectLinks>
            <a href="https://react-timeline.com">🚀 Visit project</a>
            <a href="https://github.com/steven-mercatante/react-timeline">
              <GitHub height="26" />
              &nbsp; View source code
            </a>
          </ProjectLinks>
        </article>
      </section>
      <Newsletter
        body="Subscribe to be notified when I publish new content and release new projects."
        referrer="projects - react timeline"
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ProjectReactTimeline {
    site {
      siteMetadata {
        title
      }
    }
  }
`
